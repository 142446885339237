import * as React from "react";
import PERM from "../PERM";
import { Route } from "react-router-dom";
import PagePermission from "./PagePermission";
import { Rout } from "./TYPE";
import _ from "lodash";
import { Id } from "react-toastify";

const defaultRout = "/panel";
export const PRIVATE_ROUTS = {
  uniq: "panel",
  rout: defaultRout,
  permissions: [PERM.LOGIN_REQUIRE._],
  title: "panel",
  redirect: "/panel",
  icon: "fa-solid fa-grid-horizontal",
  Element: React.lazy(() => import("./PrivateRouts")),
  Child: {
    Dashboard: {
      uniq: "dashboard",
      rout: "/panel/dashboard",
      // title: "Dashboard",
      title: null,
      menuTitle: "Dashboard",
      breadcrumbTitle: "Home",
      redirect: "/",
      index: true,
      permissions: [],
      icon: "fa-regular fa-house-blank",
      Element: React.lazy(() => import("../../pages/Dashboard")),
      Child: {},
    },
    Dashboard2: {
      uniq: "dashboard2",
      rout: "/panel/dashboard2",
      title: "Dashboard",
      menuTitle: "Dashboard",
      redirect: "/",
      index: true,
      permissions: [],
      icon: "fa-regular fa-house-blank",
      Element: React.lazy(() => import("../../pages/Dashboard2")),
      Child: {},
    },
    InvoicesWithActive: {
      uniq: "invoicesWithActive",
      rout: "/panel/invoices/:invoiceId",
      create: (invoiceNo?: number) =>
        invoiceNo ? `/panel/invoices/${invoiceNo}` : "/panel/invoices",
      title: "Invoices",
      menuTitle: "Invoices",
      redirect: "/",
      index: true,
      permissions: [],
      icon: "fa-solid fa-file-invoice",
      Element: React.lazy(() => import("../../pages/Invoice")),
      Child: {},
    },
    Requests: {
      uniq: "Requests",
      rout: null,
      title: "Support Center",
      menuTitle: "Support Center",
      redirect: "/",
      index: true,
      permissions: [],
      icon: "fas fa-headset",
      Element: React.lazy(() => import("../../pages/Requests")),
      Child: {},
    },
    MyRequests: {
      uniq: "support-center",
      rout: "/panel/support-center",
      // title: "My SupportCenter",
      title: "My Requests",
      menuTitle: "My Requests",
      redirect: "/",
      index: true,
      permissions: [],
      icon: "fas fa-headset",
      Element: React.lazy(() => import("../../pages/Requests")),
      Child: {},
    },
    CreateTicket: {
      uniq: "create-ticket",
      rout: "/panel/create-ticket",
      title: "New Requests",
      menuTitle: "New Request",
      redirect: "/",
      index: true,
      permissions: [],
      icon: "fas fa-headset",
      Element: React.lazy(() => import("../../pages/CreateTicket")),
      Child: {},
    },

    MyDocuments: {
      uniq: "documents",
      // rout: "/panel/documents",
      rout: null,
      title: "My Docs",
      menuTitle: "My Docs",
      redirect: "/",
      index: true,
      permissions: [],
      icon: "fa-light fa-file",
      Element: React.lazy(() => import("../../pages/Documents")),
      Child: {},
    },
    Invoices: {
      uniq: "invoices",
      rout: "/panel/invoices",
      title: "My Invoices",
      menuTitle: "Invoices",
      redirect: "/",
      index: true,
      permissions: [],
      icon: "fa-light fa-file-lines",
      Element: React.lazy(() => import("../../pages/Invoice")),
      Child: {},
    },

    Payments: {
      uniq: "payments",
      rout: "/panel/payments",
      title: "My Payments",
      menuTitle: "Payments",
      redirect: "/",
      index: true,
      permissions: [],
      icon: "fa-light fa-credit-card-front",
      Element: React.lazy(() => import("../../pages/Payments")),
      Child: {},
    },
    Telecommunication: {
      uniq: "telecommunication",
      rout: null,
      title: "Telecommunication",
      menuTitle: "Telecommunication",
      redirect: "/",
      index: true,
      permissions: [],
      icon: "fa-solid fa-tower-cell",
      Element: null,
      Child: {},
    },

    Hubby: {
      uniq: "hubby",
      rout: "/panel/hubby",
      title: "Hubby e-SIM",
      menuTitle: "Hubby e-SIM",
      redirect: "/",
      index: true,
      permissions: [],
      icon: "fa-solid fa-sim-card",
      Element: React.lazy(() => import("../../pages/Hubby")),
      Child: {},
    },

    Orders: {
      uniq: "orders",
      rout: "/panel/orders",
      title: "My Previous Orders",
      menuTitle: "Orders",
      redirect: "/",
      index: true,
      permissions: [],
      icon: "fa-solid fa-file-lines",
      Element: React.lazy(() => import("../../pages/Orders")),
      Child: {},
    },
    MyIds: {
      uniq: "MyIds",
      rout: "/panel/myids",
      title: "My ID's",
      menuTitle: "My ID's",
      redirect: "/",
      index: true,
      permissions: [],
      icon: "fa-solid fa-file-lines",
      Element: React.lazy(() => import("../../pages/MyIds")),
      Child: {},
    },

    Letters: {
      uniq: "letters",
      rout: "/panel/letters",
      title: "My Letters",
      menuTitle: "Letters",
      redirect: "/",
      index: true,
      permissions: [],
      icon: "fa-solid fa-credit-card-front",
      Element: React.lazy(() => import("../../pages/Letters")),
      Child: {},
    },
    Pay: {
      uniq: "pay",
      rout: "/panel/pay/:paymentPageType",
      createRout: (paymentPageType: string) => `/panel/pay/${paymentPageType}`,
      title: "Pay",
      menuTitle: "Pay",
      redirect: "/panel/invoices",
      permissions: [],
      icon: "fa-duotone fa-credit-card-front",
      Element: React.lazy(() => import("../../pages/Pay")),
      Child: {
        InvoicePay: {
          uniq: "invoicePay",
          rout: "/panel/pay/:paymentPageType/:invoiceId/:paymentsId",
          /*
                    createRout: (invoiceId: Id, paymentsId: Id[]) => `/panel/pay/invoice/${invoiceId}/${JSON.stringify(paymentsId)}`,
*/
          createRout: (invoiceId: Id, paymentsId: Id[]) =>
            `/panel/pay/invoice/${invoiceId}/${encodeURIComponent(
              JSON.stringify(paymentsId)
            )}`,
          title: "Invoice Pay",
          redirect: "/panel/invoices",
          index: true,
          permissions: [],
          icon: "fa-duotone fa-credit-card-front",
          Element: React.lazy(() => import("../../pages/Pay")),
          Child: {},
        },
        AdditionalServicesPayWithInvoiceId: {
          uniq: "additionalServicesPayWithInvoiceId",
          rout: "/panel/pay/:paymentPageType/:invoiceId",
          /*
                    createRout: (invoiceId: Id) => `/panel/pay/additional-services/${invoiceId}`,
*/
          createRout: (invoiceId: Id, paymentsId: Id[]) =>
            `/panel/pay/invoice/${invoiceId}/${encodeURIComponent(
              JSON.stringify(paymentsId)
            )}`,

          title: "Additional Service Pay",
          redirect: "/panel/additional-services",
          index: true,
          permissions: [],
          icon: "fa-duotone fa-credit-card-front",
          Element: React.lazy(() => import("../../pages/Pay")),
          Child: {},
        },
        AdditionalServicesPay: {
          uniq: "additionalServicesPay",
          rout: "/panel/pay/:paymentPageType",
          createRout: () => `/panel/pay/additional-services`,
          title: "Additional Service Pay",
          redirect: "/panel/additional-services",
          index: true,
          permissions: [],
          icon: "fa-duotone fa-credit-card-front",
          Element: React.lazy(() => import("../../pages/Pay")),
          Child: {},
        },
        ProductPay: {
          uniq: "productPay",
          rout: "/panel/pay/:paymentPageType",
          createRout: () => `/panel/pay/products`,
          title: "Products",
          redirect: "/panel/shops2",
          index: true,
          permissions: [],
          icon: "fa-duotone fa-credit-card-front",
          Element: React.lazy(() => import("../../pages/Pay")),
          Child: {},
        },
      },
    },
    Tickets: {
      uniq: "Tickets",
      rout: "/panel/tickets",
      createRout: (paymentPageType: string) =>
        `/panel/tickets/${paymentPageType}`,
      title: "Tickets",
      menuTitle: "Tickets",
      redirect: "/panel/dashboard2",
      permissions: [],
      index: false,
      icon: "fa-light fa-ticket-simple",
      Element: React.lazy(() => import("../../pages/tickets/Tickets")),
      Child: {
        Ticket: {
          uniq: "Ticket",
          rout: "/panel/tickets/:ticketId",
          createRout: (ticketId: Id) => `/panel/tickets/${ticketId}`,
          title: "Ticket",
          menuTitle: "Ticket",
          redirect: "/panel/tickets",
          permissions: [],
          index: true,
          icon: "fa-solid fa-ticket",
          Element: React.lazy(() => import("../../pages/tickets/Ticket")),
        },
      },
    },

    NewsSingle: {
      uniq: "newsSingle",
      rout: "/panel/news/:newsSlug",
      createRout: (newsSlug: string) => `/panel/news/${newsSlug}`,
      title: "News",
      menuTitle: "News",
      redirect: "/panel/news",
      permissions: [],
      icon: "fa-solid fa-grid-horizontal",
      Element: React.lazy(() => import("../../pages/news/NewsSingle")),
    },
    News: {
      uniq: "news",
      rout: "/panel/news",
      title: "News",
      menuTitle: "News",
      redirect: "/",
      permissions: [],
      icon: "fa-solid fa-grid-horizontal",
      Element: React.lazy(() => import("../../pages/news/News")),
    },
    Offers: {
      uniq: "offers",
      rout: "/panel/promotions",
      icon: "fa-light fa-rectangle-ad",
      title: "Offers",
      menuTitle: "Offers",
      redirect: "/",
      index: false,
      permissions: [],
      Element: React.lazy(() => import("../../pages/offers/Offers")),
      Child: {
        OffersSingle: {
          uniq: "offersSingle",
          rout: "/panel/promotions/:offerSlug",
          // createRout: (offerSlug: string) => PRIVATE_ROUTS.Child.Frame.create(`https://londonist.co.uk/${offerSlug}`),
          createRout: (offerSlug: string) => `/panel/promotions/${offerSlug}`,
          title: "Offers",
          menuTitle: "Offers",
          redirect: "/",
          index: true,
          permissions: [],
          icon: "fa-solid fa-grid-horizontal",
          Element: React.lazy(() => import("../../pages/offers/OffersSingle")),
          Child: {},
        },
      },
    },
    User: {
      uniq: "user",
      rout: "/panel/user",
      title: "Profile",
      menuTitle: "Profile",
      redirect: "/",
      index: true,
      permissions: [],
      icon: "fa-light fa-user",
      Element: React.lazy(() => import("../../pages/user/User")),
      Child: {},
    },
    Booking: {
      uniq: "booking",
      rout: "/panel/booking",
      title: "Booking",
      menuTitle: "Booking",
      redirect: "/",
      index: true,
      permissions: [],
      icon: "fa-solid fa-files",
      Element: React.lazy(() => import("../../pages/Booking")),
      Child: {},
    },
    Frame: {
      uniq: "frame",
      rout: "/panel/frame/:url",
      title: "Frame",
      menuTitle: "Frame",
      create: (siteUrl: string) =>
        `/panel/frame/${encodeURIComponent(siteUrl)}`,
      redirect: "/",
      index: true,
      permissions: [],
      icon: "",
      Element: React.lazy(() => import("../../pages/Frame")),
      Child: {},
    },
    Events: {
      uniq: "events",
      rout: "/panel/events",
      title: "Events",
      menuTitle: "Events",
      redirect: "/",
      index: true,
      permissions: [],
      icon: "",
      Element: React.lazy(() => import("../../pages/Events")),
      Child: {},
    },
    Ventrata: {
      uniq: "Ventrata",
      rout: "/panel/ventrata",
      title: "Ventrata",
      menuTitle: "Ventrata",
      redirect: "/",
      index: true,
      permissions: [],
      icon: "fa-light fa-ticket-simple",
      Element: React.lazy(() => import("../../pages/Ventrata/index")),
      Child: {},
    },
    Services: {
      uniq: "services",
      rout: "/panel/services",
      title: "Services",
      menuTitle: "Services",
      redirect: "/",
      index: false,
      permissions: [],
      icon: "fa-light fa-bell-concierge",
      Element: React.lazy(() => import("../../pages/services/Service")),
      Child: {
        Service: {
          uniq: "service",
          rout: "/panel/services/:serviceId",
          createRout: (serviceId: Id) => `/panel/services/${serviceId}`,
          title: "Services",
          redirect: "/",
          index: false,
          permissions: [],
          icon: "fa-solid fa-file-invoice",
          Element: React.lazy(() => import("../../pages/services/Service")),
          Child: {
            ServiceSingle: {
              uniq: "serviceSingle",
              rout: "/panel/services/:serviceId/:serviceSlug/",
              createRout: (serviceId: Id, serviceSlug: Id) =>
                `/panel/services/${serviceId}/${serviceSlug}`,
              title: null,
              redirect: "/",
              index: false,
              permissions: [],
              icon: "fa-solid fa-file-invoice",
              Element: React.lazy(() => import("../../pages/services/Service")),
              Child: {
                AdditionalServices: {
                  uniq: "addditionalServices",
                  rout: "/panel/services/:serviceId/:serviceSlug/:invoiceId",
                  createRout: (serviceId: Id, serviceSlug: Id, invoiceId: Id) =>
                    `/panel/services/${serviceId}/${serviceSlug}/${invoiceId}`,
                  title: null,
                  redirect: "/",
                  index: false,
                  permissions: [],
                  icon: "fa-solid fa-file-invoice",
                  Element: React.lazy(
                    () => import("../../pages/services/Service")
                  ),
                  Child: {
                    AdditionalServicesIdAndCategory: {
                      uniq: "additionalServicesIdAndCategory",
                      rout: "/panel/services/:serviceId/:serviceSlug/:invoiceId/:categoryId",
                      createRout: (
                        serviceId: Id,
                        serviceSlug: Id,
                        invoiceId: Id,
                        categoryId: Id
                      ) =>
                        `/panel/services/${serviceId}/${serviceSlug}/${invoiceId}/${categoryId}`,
                      title: null,
                      redirect: "/",
                      index: false,
                      permissions: [],
                      icon: "fa-solid fa-file-invoice",
                      Element: React.lazy(
                        () => import("../../pages/services/Service")
                      ),
                      Child: {
                        AdditionalServicesWithInvoiceIdAndCategoryAndProductId:
                          {
                            uniq: "AdditionalServicesWithInvoiceIdAndCategoryAndProductId",
                            rout: "/panel/services/:serviceId/:serviceSlug/:invoiceId/:categoryId/:productId",
                            createRout: (
                              serviceId: Id,
                              serviceSlug: Id,
                              invoiceId: Id,
                              categoryId: Id,
                              productId: Id
                            ) =>
                              `/panel/services/${serviceId}/${serviceSlug}/${invoiceId}/${categoryId}/${productId}`,
                            title: "Shop",
                            redirect: "/",
                            index: true,
                            permissions: [],
                            icon: "fa-solid fa-file-invoice",
                            Element: React.lazy(
                              () => import("../../pages/services/Service")
                            ),
                            Child: {},
                          },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      },
    },

    AdditionalServices: {
      uniq: "additional-services",
      rout: "/panel/additional-services",
      title: "Services",
      menuTitle: "Services",
      redirect: "/",
      index: false,
      permissions: [],
      icon: "fa-light fa-bell-concierge",
      Element: React.lazy(() => import("../../pages/AdditionalServices")),
      Child: {
        AdditionalServicesWithInvoiceId: {
          uniq: "AdditionalServicesWithInvoiceId",
          rout: "/panel/additional-services/:invoiceId",
          createRout: (invoiceId: Id) =>
            `/panel/additional-services/${invoiceId}`,
          title: "Services",
          redirect: "/",
          index: false,
          permissions: [],
          icon: "fa-solid fa-file-invoice",
          Element: React.lazy(() => import("../../pages/AdditionalServices")),
          Child: {
            AdditionalServicesIdAndCategory: {
              uniq: "AdditionalServicesIdAndCategory",
              rout: "/panel/additional-services/:invoiceId/:categoryId",
              createRout: (invoiceId: Id, categoryId: Id) =>
                `/panel/additional-services/${invoiceId}/${categoryId}`,
              title: "Shop",
              redirect: "/",
              index: false,
              permissions: [],
              icon: "fa-solid fa-file-invoice",
              Element: React.lazy(
                () => import("../../pages/AdditionalServices")
              ),
              Child: {
                AdditionalServicesWithInvoiceIdAndCategoryAndProductId: {
                  uniq: "AdditionalServicesWithInvoiceIdAndCategoryAndProductId",
                  rout: "/panel/additional-services/:invoiceId/:categoryId/:productId",
                  createRout: (invoiceId: Id, categoryId: Id, productId: Id) =>
                    `/panel/additional-services/${invoiceId}/${categoryId}/${productId}`,
                  title: "Shop",
                  redirect: "/",
                  index: true,
                  permissions: [],
                  icon: "fa-solid fa-file-invoice",
                  Element: React.lazy(
                    () => import("../../pages/AdditionalServices")
                  ),
                  Child: {},
                },
              },
            },
          },
        },
      },
    },
    Shops: {
      uniq: "shops",
      rout: "/panel/shops",
      title: "Shop",
      menuTitle: "Shop",
      redirect: "/",
      index: false,
      permissions: [],
      icon: "fa-light fa-store",
      Element: React.lazy(() => import("../../pages/Shops")),
      Child: {
        ShopsWithInvoiceId: {
          uniq: "shopsWithInvoiceId",
          rout: "/panel/shops/:invoiceId",
          createRout: (invoiceId: Id) => `/panel/shops/${invoiceId}`,
          title: "Shop",
          redirect: "/",
          index: false,
          permissions: [],
          icon: "fa-solid fa-file-invoice",
          Element: React.lazy(() => import("../../pages/Shops")),
          Child: {
            ShopsWithInvoiceIdAndCategory: {
              uniq: "shopsWithInvoiceIdAndCategory",
              rout: "/panel/shops/:invoiceId/:categoryId",
              createRout: (invoiceId: Id, categoryId: Id) =>
                `/panel/shops/${invoiceId}/${categoryId}`,
              title: "Shop",
              redirect: "/",
              index: false,
              permissions: [],
              icon: "fa-solid fa-file-invoice",
              Element: React.lazy(() => import("../../pages/Shops")),
              Child: {
                ShopsWithInvoiceIdAndCategoryAndProductId: {
                  uniq: "shopsWithInvoiceIdAndCategoryAndProductId",
                  rout: "/panel/shops/:invoiceId/:categoryId/:productId",
                  createRout: (invoiceId: Id, categoryId: Id, productId: Id) =>
                    `/panel/shops/${invoiceId}/${categoryId}/${productId}`,
                  title: "Shop",
                  redirect: "/",
                  index: true,
                  permissions: [],
                  icon: "fa-solid fa-file-invoice",
                  Element: React.lazy(() => import("../../pages/Shops")),
                  Child: {},
                },
              },
            },
          },
        },
      },
    },
    /*    Ingresso: {
            uniq: "ingresso",
            rout: "/panel/ingresso",
            title: "Ingresso",
            menuTitle: "Ingresso",
            redirect: "/",
            index: false,
            permissions: [],
            icon: " fa-regular fa-cart-shopping-fast",
            Element: React.lazy(() => import("../../pages/ingresso/Ingresso")),
            Child: {
                IngressoSingle: {
                    uniq: "ingressoSingle",
                    rout: "/panel/ingresso/:eventId",
                    createRout: (eventId: Number) => `/panel/ingresso/${eventId}`,
                    title: "Ingresso",
                    redirect: "/",
                    index: true,
                    permissions: [],
                    icon: "fa-duotone fa-credit-card-front",
                    Element: React.lazy(() => import("../../pages/ingresso/IngressoSingle")),
                    Child: {}
                }
            },
        },

        IngressoCheckout: {
            uniq: "checkout",
            rout: "/panel/ingresso/checkout",
            title: "Checkout",
            redirect: "/",
            index: false,
            permissions: [],
            icon: "fa-duotone fa-credit-card",
            Element: React.lazy(() => import("../../pages/ingresso/Checkout")),
            Child: {}
        },
*/
    Basket: {
      uniq: "basket",
      rout: "/panel/basket",
      title: "Basket",
      menuTitle: "Basket",
      redirect: "/",
      index: true,
      permissions: [],
      icon: "fa-solid fa-basket-shopping",
      Element: React.lazy(() => import("../../pages/Basket")),
      Child: {},
    },
    Request: {
      uniq: "Request",
      rout: "/panel/request",
      // title: "My Requests",
      title: null,
      menuTitle: "My Requests",
      redirect: "/",
      index: true,
      permissions: [],
      icon: "fas fa-headset",
      Element: React.lazy(() => import("../../pages/Request")),
      Child: {},
    },

    BankAccount: {
      uniq: "BankAccount",
      rout: "/panel/bank-account",
      title: "Bank Account",
      menuTitle: "Bank Account",
      redirect: "/",
      index: true,
      permissions: [],
      icon: "fa-light fa-message",
      Element: React.lazy(() => import("../../pages/BankAccount")),
      Child: {},
    },
    Demo: {
      uniq: "Demo",
      rout: "/panel/demo",
      title: "Demo",
      menuTitle: "Demo",
      redirect: "/",
      index: true,
      permissions: [],
      icon: "fa-light fa-message",
      Element: React.lazy(() => import("../../pages/Demo")),
      Child: {},
    },
    FlightTicket: {
      uniq: "flight-ticket",
      rout: "/panel/flight-ticket",
      title: "Flight Ticket",
      menuTitle: "Flight Ticket",
      redirect: "/",
      index: true,
      permissions: [],
      icon: "fa-regular fa-plane",
      Element: React.lazy(
        () => import("../../pages/FlightTickets/FlightTickets")
      ),
      Child: {},
    },
    /*Applications: {
            uniq: "Application",
            rout: "/panel/applications",
            title: "Applications",
            menuTitle: "Applications",
            redirect: "/",
            index: true,
            permissions: [],
            icon: "fa-light fa-laptop-mobile",
            Element: React.lazy(() => import("../../pages/Applications")),
            Child: {},
        }, */
  },
};

export const privateRoutsRaw = (() => {
  const routs = {
    Panel: PRIVATE_ROUTS,
  };

  function getChild(rout: any) {
    if (!rout?.Child) return {};
    _.forEach(rout?.Child, (val: Rout, key: string) => {
      // @ts-ignore
      routs[key] = val;
      getChild(val);
    });
  }

  getChild(PRIVATE_ROUTS);

  return routs;
})();

export const AUTH_ROUTS = {
  uniq: "auth",
  rout: "/auth",
  redirect: "/",
  icon: "",
  permissions: [PERM.LOGOUT_REQUIRE._],
  title: "",
  Element: React.lazy(() => import("./AuthRouts")),
  Child: {},
};

const SiteROUTS = {
  PRIVATE: PRIVATE_ROUTS,
  AUTH: AUTH_ROUTS,
};

export const BaseROUTS = {
  uniq: "base",
  title: "",
  rout: "/",
  redirect: "/",
  icon: "",
  Element: React.lazy(() => import("../../pages/App")),
  permissions: [],
  Child: SiteROUTS,
};

export default SiteROUTS;

export function RoutGenerator(routItem: any) {
  const { uniq, rout, index, Child = {} } = routItem;
  return (
    <Route
      key={uniq}
      index={index}
      path={rout}
      element={<PagePermission rout={routItem} />}
    >
      {Object.values(Child).map((rout) => RoutGenerator(rout))}
    </Route>
  );
}

export const trainTicketRout = "https://rail.londonist.co.uk";
export const tootbusRout = PRIVATE_ROUTS.Child.Frame.create(
  "https://tootbus.com/en/london/home"
);
